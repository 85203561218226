.box {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgb(112, 101, 101);
  background: #000000; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #434343,
    #787878
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #434343,
    #787878
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

@media screen and (min-width: 680px) {
  .box {
    background-image: linear-gradient(
        to left,
        rgba(47, 48, 58, 0.6),
        rgba(47, 48, 58, 0.8)
      ),
      url(../../img/bg-cover.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    background-attachment: fixed;
  }
}
