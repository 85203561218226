.gallery {
  max-width: 800px;
}

.list {
  list-style: none;
}
@media screen and (min-width: 680px) {
  .list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    padding: 0;
  }
}
.item {
  padding: 8px;
}
@media screen and (min-width: 680px) {
  .item {
    flex-basis: calc((100% - 20px) / 4);
  }
}
