html {
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
  height: 100%;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}
a {
  text-decoration: none;
}

ul {
  margin-left: 0;
  padding-left: 0;
}
