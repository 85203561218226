.itemBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 680px) {
  .itemBox {
    flex-direction: row;
    align-items: flex-start;
  }
}
.textBox {
  max-width: 500px;
}
@media screen and (min-width: 680px) {
  .textBox {
    max-width: 300px;
    padding: 0 32px 0 0;
  }
}
@media screen and (min-width: 1200px) {
  .textBox {
    max-width: 500px;
    padding: 0 32px 0 0;
  }
}
.imgBox {
  display: flex;
  flex-direction: column;
}

.border {
  width: 100%;
  border: 1px rgb(255, 255, 255) solid;
  border-radius: 1px;
  margin-top: 16px;
}
.title {
  margin: 0;
  padding: 16px 0 0 0;
  font-family: Oswald;
  font-size: 29pt;
  line-height: 1.42;
  font-weight: 400;
  text-shadow: 2px 2px 4px #1f1d1d;
  color: rgb(243, 243, 243);
  text-transform: uppercase;
}
.text {
  color: #ffffff;
  font-family: Roboto, Arial;
  font-size: 12pt;
  font-weight: 400;
  letter-spacing: 1px;
  text-shadow: 2px 2px 4px #1f1d1d;
  vertical-align: baseline;
  margin: 0;
  padding: 16px 0 0 0;
  width: 100%;
}
.img {
  padding: 16px 0 0 0;
  width: 100%;
  max-width: 400px;
}
.container {
  max-width: 1200px;
  padding: 0 24px;
  margin: 0 auto;
}
.link {
  position: relative;
  margin: 16px 0 0 0;
  padding: 8px 0 8px 0;
  width: 100%;
  border-radius: 3px;
  font-family: Roboto, Arial;
  color: #ffffff;
  text-shadow: 2px 2px 4px #1f1d1d;
  font-size: 24px;
  letter-spacing: 1px;
  line-height: 22px;
  text-align: center;
  text-decoration: none;
  z-index: 1;
  transition: 0.4s ease;

}
.link:hover {
  color: #1f1d1d;
  text-shadow: 2px 2px 4px #1f1d1d;
  transition: 0.4s ease;
}

.link::before {
  content: "";
  position: absolute;
  height: 50%;
  width: 0;
  top: 0;
  right: 0;
  background-color: rgb(255, 255, 255);
  transition: 0.4s ease;
  z-index: -1;
}
.link::after {
  content: "";
  position: absolute;
  height: 50%;
  width: 0;
  top: 50%;
  left: 0;
  background-color: rgb(255, 255, 255);
  transition: 0.4s ease;
  z-index: -1;
}
.link:hover::before,
.link:hover::after {
  width: 100%;
}
