.container {
  max-width: 1200px;
  padding: 0 24px;
  margin: 0 auto;
  color: #fff;
}
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  height: 80px;
  background-color: rgba(47, 48, 58);
  font-family: Roboto, Arial;
  font-size: 12pt;
  font-weight: 400;
}
