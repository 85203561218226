.box {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 24px;
}
@media screen and (min-width: 680px) {
  .listArtist:nth-child(2n) > div {
    flex-direction: row-reverse;
  }
}
@media screen and (min-width: 680px) {
  .listArtist > div {
    flex-direction: row;
  }
}
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 504px;
  padding: 24px;
  margin: 0 auto;
}
.orderBtn {
  border: 2px solid grey;
}

@media screen and (min-width: 680px) {
  .wrapper {
    max-width: 700px;
  }
}
@media screen and (min-width: 1200px) {
  .wrapper {
    max-width: 932px;
  }
}
.border {
  width: 100%;
  border: 1px rgb(255, 255, 255) solid;
  border-radius: 1px;
  margin-bottom: 16px;
}
.arrowLink {
  fill: #fff;
  width: 32px;
  height: 32px;
}
.listArtistName:hover .arrowLink {
  fill: #0c0909;
  text-shadow: 2px 2px 4px #1f1d1d;
  transition: 0.4s ease;
}
.title {
  text-align: center;
  color: #fff;
}
.description {
  color: #fff;
}

@media screen and (min-width: 680px) {
  .description {
    padding: 0 16px;
  }
}
.historyTitle {
  color: #fff;
}
.img {
  padding: 0;
  width: 100%;
  height: fit-content;
  max-width: 400px;
}
.listArtist {
  list-style-type: none;
}
.listArtistName {
  min-width: 64px;
  text-align: center;
  list-style-type: none;
  margin-bottom: 16px;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  transition: 0.4s ease;
}
.linkArtist {
  margin: 16px 0 0 0;
  padding: 8px 4px 8px 4px;
  width: 100%;
  border-radius: 3px;
  font-family: Roboto, Arial;
  color: #ffffff;
  text-shadow: 2px 2px 4px #1f1d1d;
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 22px;
  text-align: center;
  text-decoration: none;
}
.listArtistName:hover .linkArtist {
  color: #1f1d1d;
  text-shadow: 2px 2px 4px #1f1d1d;
  transition: 0.4s ease;
}

.listArtistName::before {
  content: "";
  position: absolute;
  height: 50%;
  width: 0;
  top: 0;
  right: 0;
  background-color: rgb(255, 255, 255);
  transition: 0.4s ease;
  z-index: -1;
}
.listArtistName::after {
  content: "";
  position: absolute;
  height: 50%;
  width: 0;
  top: 50%;
  left: 0;
  background-color: rgb(255, 255, 255);
  transition: 0.4s ease;
  z-index: -1;
}
.listArtistName:hover::before,
.listArtistName:hover::after {
  width: 100%;
}

.linkWebsite {
  text-transform: uppercase;
  color: rgb(255, 255, 255);
}
